.weather h1 {
  font-size: 25px;
  font-family: "Belanosima", sans-serif;
  font-weight: 300;
  color: rgb(0, 0, 0);
}
.weather .temperature {
  font-family: "Belanosima", sans-serif;
  font-weight: 300;
  font-size: 25px;
  color: rgb(0, 0, 0);
}
.weather .units {
  font-size: 14px;
  position: relative;
  top: -10px;
}
.weather ul {
  margin: 0;
  padding: 0;
}
.weather li {
  font-size: 16px;
  font-weight: 100;
  list-style: none;
}

.weather img {
  margin: 0;
  padding: 0;
}
.weather .description {
  position: relative;
  top: -14px;
  font-weight: 200px;
  font-size: 20px;
  color: black;
}
.material-symbols-outlined {
  color: rgb(218, 10, 10);
}
