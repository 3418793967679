.App {
  width: 540px;
  height: 500px;
  display: block;
  margin: 0 auto;
  margin-top: 100px;
  text-shadow: 0.2px 1px #000000;
  color: rgb(2, 2, 2);
}
footer {
  color: rgb(58, 58, 58);
  font-size: small;
  text-decoration: none;
  text-shadow: none;
}
a {
  text-decoration: none;
  color: rgb(2, 92, 2);
}
